// This file can be replaced during build by using the `fileReplacements` array.	
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.	
// The list of file replacements can be found in `angular.json`.	

export const environment = {	
  production: false,	
  firebase: {	
    apiKey: "AIzaSyButwG-a_vulEcPm1G7NprExC-bJ0bOH94",	
    authDomain: "task-list-76d3e.firebaseapp.com",	
    databaseURL: "https://task-list-76d3e.firebaseio.com",	
    projectId: "task-list-76d3e",	
    storageBucket: "task-list-76d3e.appspot.com",	
    messagingSenderId: "956082765440",	
    appId: "1:956082765440:web:821b52d5295022af327065",	
    measurementId: "G-HPWM1W3LN0"	
  },	
};	

/*	
 * For easier debugging in development mode, you can import the following file	
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.	
 *	
 * This import should be commented out in production mode because it will have a negative impact	
 * on performance if an error is thrown.	
 */	
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.