<div class="container-fluid mt-5">
  <div class="row">
    <div class="ml-5 mb-5">
      <app-add-task></app-add-task>
      <app-clear-completed-tasks class="ml-2"></app-clear-completed-tasks>
    </div>
  </div>

  <app-weekly-planner></app-weekly-planner>

  <div class="row justify-content-between">
    <div class="col-lg-7 col-12 ml-lg-3 mb-5">
      <app-calendar></app-calendar>
    </div>
    <div class="col-lg-4 col-12 mr-lg-5 m-0">
      <app-all-tasks></app-all-tasks>
    </div>
  </div>
</div>