<div class="container-fluid mb-5">
  <div class="row mb-2">
    <div class="col-4"><app-daily-task-list [dates]="monday"></app-daily-task-list></div>
    <div class="col-4"><app-daily-task-list [dates]="tuesday"></app-daily-task-list></div>
    <div class="col-4"><app-daily-task-list [dates]="wednesday"></app-daily-task-list></div>
  </div>
  <div class="row">
    <div class="col-4"><app-daily-task-list [dates]="thursday"></app-daily-task-list></div>
    <div class="col-4"><app-daily-task-list [dates]="friday"></app-daily-task-list></div>
    <div class="col-4"><app-daily-task-list [dates]="weekend"></app-daily-task-list></div>
  </div>
</div>
