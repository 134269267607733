<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <mat-form-field class="w-100">
    <mat-label>Title</mat-label>
    <input matInput [(ngModel)]="data.task.title"
      id="titleInput" name="titleInput" required #titleInput="ngModel">
  </mat-form-field>
  <div *ngIf="titleInput.invalid && (titleInput.dirty || titleInput.touched)"
    class="alert alert-danger">
    <div *ngIf="titleInput.errors.required">
      Please provide a title.
    </div>
  </div>

  <mat-form-field class="w-100">
    <mat-label>Details</mat-label>
    <textarea matInput [(ngModel)]="data.task.description" placeholder="Details..."></textarea>
  </mat-form-field>
  
  <mat-form-field appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="getDateFormControl()"
      (dateChange)="onDateChange($event)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions class="my-3 float-right">
  <button *ngIf="data.canDelete" mat-mini-fab color="warn" aria-label="Delete button" (click)="deleteTask(data.task)">
    <mat-icon>delete</mat-icon>
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="false" (click)="onSubmit(data.task)">Submit</button>
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
</div>